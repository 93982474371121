/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';
import TermAndPrivacySection from '../../components/TermAndPrivacySection';
import TERMS_MOBI from './terms_mobi';
import TERMS_MOBI_MALAY from './terms_mobi_malay';
import TERMS_MOBI_GUAM from './terms_mobi_guam';
import TERMS_MOBI_BALI from './terms_mobi_bali';
import TERMS_MOBI_HAWAII from './terms_mobi_hawaii';
import APPENDIX_A_MALAY from './appendix_a_malay';
import TERMS_MOBI_VI from './terms_mobi_vi';
import TermAndPrivacySingapore from './terms_mobi2.jsx';
import TermAndPrivacyPhilip from './terms_mobi_ philip.jsx';

import './styles.css';
import {withTranslation} from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import {Grid} from '@material-ui/core';
// import SelectMultiLang from '../../components/SelectMultiLang';

/**
 * MobiTerms
 */
class MobiTerms extends Component {
  /**
   * @param {*} event
   * @param {*} tag
   */
  handleClick = (event, tag) => {
    const link = '#' + tag;
    const anchor = (event.target.ownerDocument || document).querySelector(link);
    if (anchor) {
      anchor.scrollIntoView({behavior: 'smooth'});
    }
  };
  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    return (
      <div style={{wordBreak: 'break-word'}}>
        <div className="anchor_link">
          <div className="anchor_link_item mb-8" onClick={(event) => this.handleClick(event, 'ja')}>
            <span className="mr-24">日本でお使いの方 </span>
            <div className="anchor_link_icon">
              <ArrowDropDownIcon />
            </div>
          </div>
          <div className="anchor_link_item mb-8" onClick={(event) => this.handleClick(event, 'sg')}>
            <span className="mr-24">For users in Singapore</span>
            <div className="anchor_link_icon">
              <ArrowDropDownIcon />
            </div>
          </div>
          <div className="anchor_link_item" onClick={(event) => this.handleClick(event, 'vi')}>
            <span className="mr-24">For users in Vietnam</span>
            <div className="anchor_link_icon">
              <ArrowDropDownIcon />
            </div>
          </div>
          <div className="mb-8" style={{fontSize: '12px', color: 'darkgray'}}>
            Cho người dùng ở Việt Nam
          </div>
          <div className="anchor_link_item" onClick={(event) => this.handleClick(event, 'malay')}>
            <span className="mr-24">For users in Malaysia</span>
            <div className="anchor_link_icon">
              <ArrowDropDownIcon />
            </div>
          </div>
          <div className="anchor_link_item" onClick={(event) => this.handleClick(event, 'guam')}>
            <span className="mr-24">グアムでお使いの方</span>
            <div className="anchor_link_icon">
              <ArrowDropDownIcon />
            </div>
          </div>
          <div className="anchor_link_item" onClick={(event) => this.handleClick(event, 'bali')}>
            <span className="mr-24">インドネシア バリ島「バリバリmobi」をお使いの方</span>
            <div className="anchor_link_icon">
              <ArrowDropDownIcon />
            </div>
          </div>
          <div className="anchor_link_item" onClick={(event) => this.handleClick(event, 'hawaii')}>
            <span className="mr-24">ハワイ「オリオリモビ」をお使いの方</span>
            <div className="anchor_link_icon">
              <ArrowDropDownIcon />
            </div>
          </div>
          <div className="anchor_link_item" onClick={(event) => this.handleClick(event, 'philip')}>
            <span className="mr-24">フィリピン セブ島（ハロハロモビ）でお使いの方</span>
            <div className="anchor_link_icon">
              <ArrowDropDownIcon />
            </div>
          </div>
        </div>
        <div className="Terms" id="ja">
          {TERMS_MOBI.map((section, i) => (
            <TermAndPrivacySection section={section} i={i} key={i} />
          ))}
          <br />
          <p>{this.props.t('established_year_1')}</p>
          <p>{this.props.t('established_year_2')}</p>
          <p>{this.props.t('established_year_3')}</p>
        </div>
        <div className="Terms count" id="sg">
          <TermAndPrivacySingapore></TermAndPrivacySingapore>
        </div>
        <div className="Terms count" id="vi">
          {TERMS_MOBI_VI.map((section, i) => (
            <TermAndPrivacySection section={section} i={i} key={i} />
          ))}
          <br />
          <p className="text-note">2024年4月1日　制定</p>
        </div>
        <div className="Terms count" id="malay">
          {TERMS_MOBI_MALAY.map((section, i) => (
            <TermAndPrivacySection section={section} i={i} key={i} />
          ))}
          <p className="text-note">Effective Date: 1st Feb 2023</p>
          <br />
        </div>
        <div className="Terms">
          {APPENDIX_A_MALAY.map((section, i) => (
            <TermAndPrivacySection section={section} i={i} key={i} />
          ))}
          <p className="text-note">Effective Date: 29 Jan, 2023</p>
          <br />
        </div>
        <div className="Terms count" id="guam">
          {TERMS_MOBI_GUAM.map((section, i) => (
            <TermAndPrivacySection section={section} i={i} key={i} />
          ))}
          <br />
          <p>2024年2月6日　制定</p>
        </div>
        <div className="Terms count" id="bali">
          {TERMS_MOBI_BALI.map((section, i) => (
            <TermAndPrivacySection section={section} i={i} key={i} />
          ))}
          <br />
          <p>2025年01月16日　制定</p>
        </div>
        <div className="Terms count" id="hawaii">
          {TERMS_MOBI_HAWAII.map((section, i) => (
            <TermAndPrivacySection section={section} i={i} key={i} />
          ))}
          <br />
          <p>2024年12月25日　制定</p>
        </div>
        <div className="Terms count" id="philip">
          <TermAndPrivacyPhilip></TermAndPrivacyPhilip>
        </div>
      </div>
    );
  }
}

export default withTranslation('translations')(MobiTerms);
