import React, {Component} from 'react';
import './styles.css';
import {withTranslation} from 'react-i18next';

/**
 * TermMobiSingapore
 */
class TermMobiSingapore extends Component {
  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    return (
      <>
        <div className="section">
          <h2 className="section-title">シンmobi利用規約</h2>
          <p className="section-description">
            <div>
              シンmobi利用規約（以下、「本規約」といいます。）は、JTB Pte
              Ltd（以下、「当社」といいます。）が、主として、「mobi Community Mobility
              アプリ」（以下、「本アプリ」といいます。）を通じて運営・提供するシンmobiサービスの利用条件等を定めるものです。
            </div>
          </p>
          <h2 className="section-title">（適用範囲） </h2>
          <p className="section-description">
            第１条「本アプリ」を自ら保有又は管理するスマートフォン等の携帯型情報端末にインストールした者が、本アプリを利用して当社の提供するシンmobiサービスを利用する場合、本規約が適用されます。本規約に定めのない事項については、シンガポールの法令の定めるところ又は一般の慣習によります。
          </p>
          <p className="section-description">
            2.
            本アプリを利用して当社の運行するシンmobiサービスを利用する者（以下、「利用者」といいます。申込する者も乗車する者も含みます。）は「mobi
            Community
            Mobilityアプリ利用規約（https://travel.willer.co.jp/static/img/maas/mobi/service-revised-20240701.pdf）」（以下、「原規約」といいます。）を全て確認の上、同意したものとみなします。
          </p>
          <p className="section-description">
            3. 当社は、運送サービスを当社グループ会社であるBig S' Holiday
            Pte.Ltd.（以下、「運行会社」と言います。）に委託し、その予約、運行等を管理します。当社およびBig S' Holiday
            Pte.Ltd.は共同して原規約における運送サービス提供事業者となります。
          </p>
          <p className="section-description">
            4.
            本規約の内容は、当社の判断において、以下の場合に利用者に対して何らの事前の通知なしに変更することがあります。この場合、変更日以降の利用については、変更後の本規約等の内容が適用されます。なお、当社は、本規約等の内容を変更する場合、合理的な期間をもって事前に、利用者に変更内容を通知するものとします。
            <ul className="num-kakko">
              <li>本規約の内容変更が、利用者の一般の利益のために実施されるとき</li>
              <li>
                本規約の内容変更が、本規約等の目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。
              </li>
            </ul>
          </p>
          <p className="section-description">
            ５.
            本規約等の定めの一部の効力が、法令又は判決により無効となった又は取り消された場合においても、残りの定めの有効性、適法性及び法的強制力はなお有効であるものとします。
          </p>
          <h2 className="section-title">（原規約の準用）</h2>
          <p className="section-description">
            第2条　原規約第4条から第10条の定めは、本規約に準用します。準用された原規約における「当社」は、本規約に定めるJTB
            Pte Ltdに読み替えるものとします。
          </p>
          <p className="section-description">（シンmobiサービスの内容・乗合サービス）</p>
          <p className="section-description">
            第3条　シンmobiサービスにおいて、利用者は、本アプリを使用して当社が設定する仮想乗降場所の中から乗車地及び降車地を選択し、その区間における乗合利用者自動車運送サービス（以下、「運送サービス」といいます。）の提供を受けることができます。
          </p>
          <p>
            2.
            前項に定める運送サービスは、利用者が仮想乗降場所の中から設定した乗車地から降車地までの区間の運送サービスを申し込む都度一回限りのサービスであり、以下「一回利用サービス」といいます。
          </p>
          <p>
            3.
            シンmobiは、乗合旅客運送サービスです。ほぼ同一方角を走行する場合は、一部区間はあるいは全区間において他の利用者と乗り合わせる場合があります。
          </p>
          <h2 className="section-title">（利用者の責務）</h2>
          <p className="section-description">
            第6条　利用者は、運行会社の車両の運転者、その他の係員（以下総称して「乗務員等」といいます。）が運送の安全確保と車内秩序の維持のために行う職務上の指示に従わなければなりません。なお、利用者等が本条に違反する行為又は違反する恐れのある行為があったと合理的に乗務員等が認めた場合、かかる乗務員等は当該車両の管理者として、この利用者等に対し、直ちに乗車を拒み又は降車を命じることができるものとします。
          </p>
          <p>2. 利用者等は、みだりに申し込みと取消を繰り返し行ってはならないものとします。</p>
          <p>
            3.
            当社は、利用者の故意若しくは過失、又は法令若しくは本規約違反により、当社又は運行会社が損害を受けたときは、利用者に対し、損害賠償請求をします。
          </p>
          <h2 className="section-title">（運行エリア）</h2>
          <p className="section-description">
            第7条
            シンmobiは、本アプリに表示される運行エリア内が運送サービス提供の範囲であり、当該エリアを越える運送サービスの提供はできません。
          </p>
          <h2 className="section-title">（乗降車の場所）</h2>
          <p className="section-description">
            第8条
            前第３条に定める仮想乗降場所を「Waypoint」といい、利用者は本アプリに表示されるWaypoint以外で乗車、又は降車できません。
          </p>
          <p className="section-description">
            2.
            利用者は、本アプリにて事前に指定したWaypointから乗車し、同じく指定したWaypointにて降車します。指定したWaypoint以外での乗降車はできません。
          </p>
          <p className="section-description">3. 利用者は、乗車後に降車場所として指定したWaypointの変更はできません。</p>
          <h2 className="section-title">（本アプリ提示による乗車）</h2>
          <p className="section-description">
            第9条 利用者は、本アプリの所定の画面を乗務員等に提示しなければ乗車できません。
          </p>
          <p className="section-description">2. 利用者は、本アプリに設定した乗車人員を超えて乗車できません。</p>
          <h2 className="section-title">（車内持ち込み手荷物）</h2>
          <p className="section-description">
            第10条 利用者は、自己の身の回り品は持ち込むことができます。但し、以下の物は持ち込めません。
          </p>
          <ol>
            <li>利用者は、スーツケース等の大きな荷物を車内に持ち込むことはできません。</li>
            <li>利用者は、ドリアンなどのにおいの強い食品を車内に持ち込むことはできません。</li>
          </ol>
          <h2 className="section-title">（運送の引受け及び継続の拒絶）</h2>
          <p className="section-description">
            第11条 当社は、次の各号のいずれかに該当する場合には、運送サービスの引受け又は継続を拒絶することがあります。
          </p>
          <ul className="num-kakko">
            <li>当該運送サービスの申込みがこの利用規約によらないものであるとき</li>
            <li>当該運送サービスに適する設備がないとき</li>
            <li>当該運送サービス提供に関し、利用者から特別な負担を求められたとき</li>
            <li>当該運送サービスが法令の規定又は公の秩序若しくは善良の風俗に反するものであるとき</li>
            <li>天災その他やむを得ない事由による運送上の支障があるとき</li>
            <li>利用者が乗務員等の行う運送の安全確保と車内秩序の維持のための指示に従わないとき</li>
            <li>
              利用者が、爆発の恐れがあるもの、燃えやすいもの、人に危害を与えたり他の物件を損傷する恐れがあるものなど、凶器や危険物全般を携帯しているとき
            </li>
            <li>
              利用者が凶器や危険物全般を携帯しているおそれがあると認められ、乗務員等が携行品の明示を求めた場合で利用者がその求めに応じないとき
            </li>
            <li>
              利用者が泥酔している場合、又は不潔な服装をしている場合、又はにおいの強い食品（ドリアン等）を所持している場合で、他の利用者の迷惑となるおそれのあるとき
            </li>
            <li>利用者が社内で飲食した場合（シンガポールでは車内での飲食は法律で禁止されております。）</li>
            <li>利用者がスーツケース等大きな荷物を大きな荷物を</li>
            <li>利用者が付添人を伴わない重病者であるとき</li>
            <li>18歳未満の未成年者が単独で利用するとき</li>
          </ul>
          <h2 className="section-title">（携行品等に関する責任）</h2>
          <p className="section-description">
            第12条
            当社は、利用者の手荷物及び着衣、メガネ、時計その他の身の回り品について滅失、き損、汚損等によって生じた損害について責任をを一切負いません。ただし、当社、運行会社又は乗務員等がその滅失、き損、汚損等について過失があったときは、この限りでありません。
          </p>
          <h2 className="section-title">（異常気象時等における措置に関する責任）</h2>
          <p className="section-description">
            第13条
            当社及び運行会社は、天災、その他当社または運行会社の責に帰することができない事由により、運送サービスの安全確保のため一時的に運行中止、又はその他の措置をとることがあります。この場合、当社及び運行会社は利用者に対し損害賠償責任を負いません。
          </p>
          <h2 className="section-title">（利用者等の情報）</h2>
          <p className="section-description">
            第14条
            利用者等は、シンmobiサービスの申し込みに際し、当社が本アプリを通じて、運送サービスの提供を目的として、利用者等の氏名、性別、連絡先等の個人情報を必要な範囲において提供を受けること、及び当社の定めるプライバシーポリシー又は個人情報取り扱い方針等に同意するものとします。
          </p>
          <p className="section-description">
            2.
            当社は、前項に基づき又はシンmobiサービス提供の過程で取得した利用者等の個人情報について、シンガポールの個人情報保護法（Personal
            Data Protection Act）と関連規定に基づき、適切に管理するものとします。
          </p>
          <p className="section-description">
            3.
            前１項に関して、当社の定めるプライバシーポリシー又は個人情報取り扱い方針等並びに個人情報に関する問い合わせ先は、本アプリに掲載します。
          </p>
          <h2 className="section-title">（反社会的勢力の排除）</h2>
          <p className="section-description">
            第15条
            利用者等は、自らが以下の各号のいずれにも該当しないこと及び以下の各号の個人又は団体と密接な交流関係を有していないことを表明して、確約するものとします。
            <ul className="num-kakko">
              <li>暴力団</li>
              <li>暴力団の構成員（準構成員を含む。以下同じ。）</li>
              <li>
                暴力団関係企業又は暴力団若しくは暴力団の構成員が出資若しくは業務執行に関して重要な地位に就いている団体
              </li>
              <li>総会屋、社会運動標榜ゴロその他反社会的勢力に該当するもの</li>
              <li>特殊知能暴力集団その他反社会的勢力に該当する団体又はその構成員</li>
              <li>その他前各号に準ずるもの</li>
            </ul>
          </p>
          <p className="section-description">
            2.
            利用者等は、自ら又は第三者を利用して、以下の各号に該当する行為を行わないことを表明して、確約するものとします。
            <ul className="num-kakko">
              <li>暴力的な又は法的な責任を超えた不当な要求行為</li>
              <li>取引に関して、脅迫的な言動をし、又は暴力を用いる行為</li>
              <li>風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為</li>
              <li>その他前各号に準ずる行為</li>
            </ul>
          </p>
          <p className="section-description">
            3.
            当社は、利用者等が前2項に定める表明事項に違反していた場合又は違反していると判断した場合、直ちにシンmobiサービスの提供を中止し、以後の提供を拒絶することができるとともに、これらの場合、当事者等は、自己の違反により、当社がこれにより被った全ての損害（風評被害等による逸失利益、弁護士費用等を含むがこれに限らない。）を賠償しなければならないものとします。
          </p>
          <h2 className="section-title">（準拠法）</h2>
          <p className="section-description">
            第16条 本規約及び付随する規則等は、シンガポール法によって解釈されるものとします。
          </p>
          <p className="section-description">
            2.
            本規約に関する紛争については、シンガポールの民事訴訟により当社の登録住所を管轄する裁判所を第一審の独占的合意管轄裁判所とします。ただし、適用される強制法規により別の裁判所への提訴が許される場合は、その定めによります。
          </p>
          <p className="section-description">2025年2月1日　制定</p>
        </div>
      </>
    );
  }
}

export default withTranslation('translations')(TermMobiSingapore);
